<template>
<div class="TheLogin">
  <div style="width: 400px;height: 100px;margin: 0 auto;padding-top: 300px" >
    <el-form ref="LoginForm" :rules="rules" :model="form" label-width="80px">
      <el-form-item label="账号：" prop="userName">
        <el-input  v-model="form.username" placeholder="请输入账号"></el-input>
      </el-form-item>
      <el-form-item label="密码：" prop="Password">
        <el-input placeholder="请输入密码" v-model="form.password" show-password></el-input>
      </el-form-item>
      <el-form-item  style="padding-left: 75px" >
        <el-button type="primary" @click="submit('LoginForm')">登录</el-button>
        <el-button @click="resetForm('form')">重置</el-button>
      </el-form-item>

    </el-form>
  </div>

</div>



</template>

<script>
import Hard from '@/components/Hard.vue'
import qs from 'qs'
export default {

  name: "TheLogin",
  components:{
    'Hard':Hard
  },
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'change' },
          { min: 0, max: 16, message: '长度在 5 到 16 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods:{
    submit:function (formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios.post('http://47.103.147.94:7020/login',qs.stringify({ "username":this.form.username,
            "password":this.form.password}),{
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
          }).then(function (resp) {
            console.log(resp.data.data.token)
            localStorage.setItem("token",resp.data.data.token)
            window.location = "/"


          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      location.reload();
    }
  }




}

</script>

<style scoped>

</style>
